import React, { useState, useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useInterceptors } from './common/api'
// import { getDbSecretKey } from 'src/services/userService'
import { SetLocalStorage } from 'src/common/utility'

// import { useInterceptors } from './common/api'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/forgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/resetPassword'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const InvalidLink = React.lazy(() => import('./views/pages/invalidLink/InvalidLink'))

const Router = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // const [secretKey, setSecretKey] = useState()
  // useEffect(() => {
  //   setDbKey()
  //   // eslint-disable-next-line

  // }, [location.pathname === '/login'])

  // const setDbKey = async () => {
  //   const dbSecretKey = localStorage.getItem('dbSecretKey')
  //   if (!dbSecretKey) {
  //     try {
  //       const keyResponse = await getDbSecretKey(window.location.origin)
  //       if (keyResponse.status === 200) {
  //         SetLocalStorage('dbSecretKey', keyResponse.data.dbSecretKey)
  //         setSecretKey(keyResponse.data.dbSecretKey)
  //       }
  //     } catch (e) {
  //       // navigate('/invalid-link')
  //     }
  //   } else {
  //     setSecretKey(dbSecretKey)
  //   }
  // }
  useInterceptors()

  return (
    <Routes>
      <Route exact path="/login" name="Login Page" element={<Login />} />
      <Route exact path="/register" name="Register Page" element={<Register />} />
      <Route
        exact
        path="/forgot-password"
        name="Forgot Password Page"
        element={<ForgotPassword />}
      />
      <Route exact path="/reset-password" name="Reset Password Page" element={<ResetPassword />} />
      <Route exact path="/invalid-link" element={<InvalidLink />} />
      <Route exact path="/404" name="Page 404" element={<Page404 />} />
      <Route exact path="/500" name="Page 500" element={<Page500 />} />
      {/* <DefaultLayout /> */}
      <Route path="*" name="Home" element={<DefaultLayout />} />
    </Routes>
  )
}

export default Router
