import axios from 'axios'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import paths from 'src/constants/paths'
import { useCoinContext } from 'src/providers/CoinProvider'
import {
  ValueFromUserData,
  BASE_URL as API_BASE,
  downloadFile,
  RemoveFromLocalStorage,
  UserDataKey,
} from './utility'

export const api = axios.create({
  baseURL: API_BASE,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

//Axios Interceptor //
// Request
export function useInterceptors() {
  const coinContext = useCoinContext()
  const navigate = useNavigate()

  useEffect(() => {
    // if (secretKey) {
      // console.log('true true true')
      const requestInterceptorId = api.interceptors.request.use(
        function (config) {
          const token = ValueFromUserData('token')
          // const dbSecretKey = localStorage.getItem('dbSecretKey')
          if (coinContext.isLoaded) {
            config.headers['Selected-Coin'] = coinContext.selectedCoin?._id ?? ''
          }

          config.headers['Content-Type'] = 'application/json'
          if (token) {
            config.headers.Authorization = `${token}`
          }
          // config.headers.secretdbkey = secretKey

          return config
        },
        function (err) {
          return Promise.reject(err)
        },
      )

      return () => {
        api.interceptors.request.eject(requestInterceptorId)
      }
    // }
    // eslint-disable-next-line

  }, [coinContext.selectedCoin])

  useEffect(() => {
    const id = api.interceptors.response.use(undefined, (value) => {
      if (value.response.data.status === 401) {
        RemoveFromLocalStorage(UserDataKey)
        navigate(paths.getLogin())
        throw value
      }
      throw value
    })

    return () => {
      api.interceptors.response.eject(id)
    }
    // eslint-disable-next-line

  }, [])

  return {}
}

// GET REQUEST
export const GetData = async (END_POINT, id) => {
  let response

  if (id) {
    response = await api.get(END_POINT, {
      params: { id },
    })
  } else {
    response = await api.get(END_POINT)
  }
  // if (response.status && !response.data.data) {
  //   const url = URL.createObjectURL(new Blob([response.data], { type: `text/csv` }))
  //   downloadFile(url, `${END_POINT.split('/')[0]}_${Date.now()}.csv`)
  // }
  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}
export const GetData1 = async (END_POINT, id) => {
  let response

  if (id) {
    response = await api.get(END_POINT, {
      params: { id },
    })
  } else {
    response = await api.get(END_POINT)
  }
  if (response.status && !response.data.data) {
    const url = URL.createObjectURL(new Blob([response.data], { type: `text/csv` }))
    downloadFile(url, `${END_POINT.split('/')[0]}_${Date.now()}.csv`)
  }
  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}

// GET GOD PANNEL REQUEST
// export const GetGodData = async (END_POINT, id) => {
//   let POINT
//   if (window.location.origin.includes('localhost')) {
//     // POINT = 'https://terx.io'
//     // POINT = 'https://skisip.zip2box.com'
//     // POINT = 'https://admin.cryptoetrade.site'
//     POINT = 'https://tausif-admin.zip2box.com'
//     // POINT = 'https://staging-admin.zip2box.com'
//     // POINT = 'https://esg-admin.zip2box.com'
//     // POINT = 'https://admin.terx.io'
//     // POINT = 'https://harpret-admin.zip2box.com'
//     // POINT = 'https://zip2box.com'
//     // POINT = 'https://playtopromote.com'
//     // console.log('LIVED POINT RUNNING')
//   }
//   let response

//   if (id) {
//     // response = await api.get(END_POINT, {
//     //   params: { id },
//     // })
//   } else {
//     response = await axios.get(
//       `https://ico-godpanel-apis.zip2box.com/api/client/key?link=${POINT ? POINT : END_POINT}/`,
//       // `https://ico-godpanel-apis.staging.zip2box.com/api/client/key?link=${
//       //   POINT ? POINT : END_POINT
//       // }/`,
//     )
//   }
//   if (response.status && !response.data.data) {
//     const url = URL.createObjectURL(new Blob([response.data], { type: `text/csv` }))
//     downloadFile(url, `${END_POINT.split('/')[0]}.csv`)
//   }
//   return {
//     data: response.data.data,
//     message: response.data.message,
//     error: response.data.error,
//     status: response.data.status,
//   }
// }

// POST REQUEST
export const PostData = async (END_POINT, body, config) => {
  // console.log('endpoint', END_POINT, 'body', body, 'config', config)
  const response = await api.post(END_POINT, body, config)
  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}

export const PutData = async (END_POINT, body, config) => {
  const response = await api.put(END_POINT, body, config)

  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}
// POST REQUEST
export const PostDataAsForm = async (END_POINT, body, config) => {
  const formData = new FormData()
  for (const item in body) {
    formData.append(item, body[item])
  }
  const response = await api.post(END_POINT, formData, config)

  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}

//DELETE REQUEST
export const DeleteData = async (END_POINT, id) => {
  const response = await api.delete(`${API_BASE}${END_POINT}`, {
    params: { id },
  })
  return {
    data: response.data.data,
    message: response.data.message,
    error: response.data.error,
    status: response.data.status,
  }
}

export const DeleteDataWithBody = async (END_POINT, body) => {
  // console.log('body', body)

  const response = await api.delete(`${API_BASE}${END_POINT}`, {
    data: body,
  })
  return { data: response.data, message: response.data.message }
}

// get request with support of query
export const getDataWithQuery = async (END_POINT, queries) => {
  let response

  if (queries) {
    response = await api.get(END_POINT, {
      params: queries,
    })
  } else {
    response = await api.get(END_POINT)
  }

  return { data: response.data.data, message: response.data.message }
}
