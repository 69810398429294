import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useDispatch } from 'react-redux'
import { setSidebarToggle } from 'src/redux/actions/common'

export default function useInit() {
  const dispatch = useDispatch()
  const isMobileOrTab = useMediaQuery({
    query: '(max-width: 992px)',
  })
  // eslint-disable-next-line

  useEffect(() => {
    if (isMobileOrTab) {
      dispatch(setSidebarToggle(false))
    }
    // eslint-disable-next-line

  }, [])

  return {}
}
